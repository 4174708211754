import auth from "../middlewares/auth";
export const workRoutes = [
  {
    path: '/works',
    component: () => import(/* webpackChunkName: "works" */ '../views/work/WorkList.vue'),
    name: 'works',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];