import auth from "../middlewares/auth";
export const wowRoutes = [
  {
    path: '/wow',
    component: () => import(/* webpackChunkName: "settings" */ '../views/wow/WowList'),
    name: 'wow',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/wow/add',
    component: () => import(/* webpackChunkName: "settings" */ '../views/wow/WowForm'),
    name: 'wow_add',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/wow/edit/:id',
    component: () => import(/* webpackChunkName: "settings" */ '../views/wow/WowForm'),
    name: 'wow_edit',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];