// import auth from "../middlewares/auth";
export const donationRoutes = [
  /*{
    path: '/donations',
    component: () => import(/!* webpackChunkName: "donations" *!/ '../views/donation/DonationList.vue'),
    name: 'donations',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/donations/add',
    component: () => import(/!* webpackChunkName: "donations" *!/ '../views/donation/DonationForm.vue'),
    name: 'donations_add',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/donations/edit/:id',
    component: () => import(/!* webpackChunkName: "donations" *!/ '../views/donation/DonationForm.vue'),
    name: 'donations_edit',
    meta: {
      middleware: [
        auth
      ]
    }
  },*/
];