import auth from "../middlewares/auth";
export const inventoryRoutes = [
  {
    path: '/inventory/products',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/inventory/product/ProductList.vue'),
    name: 'inventory_product',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/inventory/products/add',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/inventory/product/ProductForm.vue'),
    name: 'inventory_product_add',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/inventory/products/edit/:id',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/inventory/product/ProductForm.vue'),
    name: 'inventory_product_edit',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/inventories',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/inventory/InventoryList'),
    name: 'inventories',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/inventories/add',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/inventory/InventoryForm'),
    name: 'inventories_add',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/inventories/edit/:id',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/inventory/InventoryForm'),
    name: 'inventories_edit',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];